import {React, useState, useEffect, useRef} from 'react';

function ConfirmModal(props) {
    const btnAccept = useRef(null)

    useEffect(() => {
        setVisible(props.visible);
        if (props.visible) setTimeout(() => btnAccept.current.focus(), 0)
    }, [props.visible])

    const [visible, setVisible] = useState(false);

    const dismiss = (e) => {
        if (!e.defaultPrevented) props.res();
    }
    const accept = () => {
        props.callback(props.param);
        props.res();
    }

    return (
        <section className={`fixed z-50 top-0 left-0 flex flex-col justify-center items-center bg-black/75 h-screen w-screen ${!visible && 'hidden'}`} onClick={dismiss}>
            <div className='flex flex-col gap-y-6  bg-grey-darker rounded-xl p-8' onClick={(e)=>e.preventDefault()}>
                <h2 className='text-xl text-grey-lightest font-semibold'>{props.title}</h2>
                <p>{props.text}</p>
                <div className='flex flex-row self-end gap-x-4'>
                    <button className='bg-grey hover:bg-grey-lighter rounded px-3 py-2' onClick={dismiss}>Nein</button>
                    <button ref={btnAccept} className='hover:bg-grey-lighter rounded px-3 py-2' onClick={accept}>Ja</button>
                </div>
            </div>
        </section>
    );
}

export default ConfirmModal;

