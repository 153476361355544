import {React, useEffect} from 'react';
import {useParams} from "react-router-dom";
import { Transition } from "@headlessui/react";
import Service from "./Service";

function Services(props) {
    let params = useParams();

    return (
        <Transition
            as='section'
            show={true}
            appear={true}
            enter="transition-transform duration-300"
            enterFrom="transform translate-x-full"
            enterTo="transform translate-x-0"
            className={`fixed top-0 z-20 Services h-screen w-screen transform translate-x-full`}>
            <Service uuid={params.imageUuid} openViewer={props.openViewer} images={props.images} services={props.services} />
        </Transition>
    );
}

export default Services;
