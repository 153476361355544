import {React} from 'react';
import {LazyLoadImage} from "react-lazy-load-image-component";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { Transition } from "@headlessui/react";

function Datenschutz(props) {
    return (
        <Transition
            as='section'
            show={true}
            appear={true}
            enter="transition-top duration-300"
            enterFrom="top-full"
            enterTo="top-0"
            className='Datenschutz fixed top-full z-20 bg-grey-dark h-screen w-screen'>
            {
                (props.images.length && props.row !== undefined) ?
                    <LazyLoadImage key='hero_img' alt=''
                                   placeholderSrc={'/images/' + props.images.find(image => image.uuid === props.row.backdrops.subpages).preview.mid}
                                   src={'/images/' + props.images.find(image => image.uuid === props.row.backdrops.subpages).preview.large}
                                   effect='none' className='object-cover h-screen w-screen'
                                   wrapperClassName='absolute top-0 bg-cover bg-center h-screen w-screen'
                    /> : ''
            }
            <div className='relative flex flex-col items-center bg-gradient-to-b from-grey-dark/50 via-grey-dark/25 to-grey-dark/50 h-full overflow-auto w-full p-4 xl:px-44 2xl:px-64 py-24 2xl:px-44'>
                <div className='space-y-6 md:max-w-xl xl:max-w-2xl 2xl:max-w-3xl 3xl:max-w-4xl w-full'>
                    <div className='flex flex-col gap-y-2'>
                        <h1 className='text-3xl font-semibold uppercase w-full'>Datenschutz</h1>
                        <Link to='/' className='flex flex-row items-center gap-x-3 hover:text-grey-lightest uppercase w-full'>
                            <FontAwesomeIcon icon={faAnglesLeft} />
                            Home
                        </Link>
                    </div>
                    <div className='bg-grey-darker/25 rounded-2xl backdrop-blur-xl w-full p-6 md:p-12'>
                        <h2 className='text-xl font-semibold mb-2'>1. Datenschutz auf einen Blick</h2>

                        <h5 className='text-lg font-semibold mb-2 mt-6'>Allgemeine Hinweise</h5>
                        <p>
                            Die folgenden Hinweise geben einen einfachen Überblick darüber,
                            was mit Ihren personenbezogenen Datenpassiert, wenn Sie diese
                            Website besuchen. Personenbezogene Daten sind alle Daten, mit
                            denen Siepersönlich identifiziert werden können. Ausführliche
                            Informationen zum Thema Datenschutz entnehmen Sie unserer unter
                            diesem Text aufgeführten Datenschutzerklärung.
                        </p>

                        <h5 className='text-lg font-semibold mb-2 mt-6'>Datenerfassung auf dieser Website</h5>
                        <h6>
                            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
                        </h6>
                        <p>
                            Die Datenverarbeitung auf dieser Website erfolgt durch den
                            Websitebetreiber. Dessen Kontaktdatenkönnen Sie dem Abschnitt
                            „Hinweis zur Verantwortlichen Stelle“ in dieser
                            Datenschutzerklärung entnehmen.
                        </p>

                        <h6>Wie erfassen wir Ihre Daten?</h6>
                        <p>
                            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
                            mitteilen. Hierbei kann es sich z. B. umDaten handeln, die Sie in
                            ein Kontaktformular eingeben.<br />Andere Daten werden automatisch
                            oder nach Ihrer Einwilligung beim Besuch der Website durch unsere
                            IT-Systeme erfasst. Das sind vor allem technische Daten (z. B.
                            Internetbrowser, Betriebssystem oder Uhrzeitdes Seitenaufrufs).
                            Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese
                            Website betreten.
                        </p>

                        <h6>Wofür nutzen wir Ihre Daten?</h6>
                        <p>
                            Ein Teil der Daten wird erhoben, um eine fehlerfreie
                            Bereitstellung der Website zu gewährleisten. Andere Daten können
                            zur Analyse Ihres Nutzerverhaltens verwendet werden.
                        </p>

                        <h6>Welche Rechte haben Sie bezüglich Ihrer Daten?</h6>
                        <p>
                            Sie haben jederzeit das Recht, unentgeltlich Auskunft über
                            Herkunft, Empfänger und Zweck Ihrergespeicherten personenbezogenen
                            Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung
                            oderLöschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung
                            zur Datenverarbeitung erteilt haben,können Sie diese Einwilligung
                            jederzeit für die Zukunft widerrufen. Außerdem haben Sie das
                            Recht, unterbestimmten Umständen die Einschränkung der
                            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                        </p>
                        <p>
                            Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen
                            Aufsichtsbehörde zu.
                        </p>
                        <p>
                            Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
                            sich jederzeit an uns wenden.
                        </p>

                        <h2 className='text-xl font-semibold mb-4 mt-12'>2. Hosting</h2>
                        <h5 className='text-lg font-semibold mb-2 mt-6'>Externes Hosting</h5>
                        <p>
                            Diese Website wird bei einem externen Dienstleister gehostet
                            (Hoster). Die personenbezogenen Daten, die auf dieser Website
                            erfasst werden, werden auf den Servern des Hosters gespeichert.
                            Hierbei kann es sich v.a. um IP-Adressen, Kontaktanfragen, Meta-
                            und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
                            Websitezugriffe und sonstige Daten, die über eine Website
                            generiert werden, handeln.
                        </p>
                        <p>
                            Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
                            gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs.
                            1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und
                            effizienten Bereitstellung unseres Online-Angebots durch einen
                            professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
                        </p>
                        <p>
                            Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies
                            zur Erfüllung seiner Leistungspflichten erforderlich ist und
                            unsere Weisungen in Bezug auf diese Daten befolgen.
                        </p>
                        <p className='pt-2'>Wir setzen folgenden Hoster ein:</p>
                        <p className='py-2'>
                            netcup GmbH<br />
                            Daimlerstraße 25<br />
                            Hauptstraße 68<br />
                            D-76185 Karlsruhe<br />
                        </p>

                        <h2 className='text-xl font-semibold mb-4 mt-12'>3. Allgemeine Hinweise und Pflichtinformationen</h2>
                        <h5 className='text-lg font-semibold mb-2 mt-6'>Datenschutz</h5>
                        <p>
                            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
                            Daten sehr ernst. Wir behandeln Ihrepersonenbezogenen Daten
                            vertraulich und entsprechend den gesetzlichen
                            Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                        </p>
                        <p>
                            Wenn Sie diese Website benutzen, werden verschiedene
                            personenbezogene Daten erhoben.Personenbezogene Daten sind Daten,
                            mit denen Sie persönlich identifiziert werden können. Die
                            vorliegende Datenschutzerklärung erläutert, welche Daten wir
                            erheben und wofür wir sie nutzen. Sie erläutert auch, wieund zu
                            welchem Zweck das geschieht.
                        </p>
                        <p>
                            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.
                            B. bei der Kommunikation per E-Mail)Sicherheitslücken aufweisen
                            kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
                            Dritte ist nicht möglich.
                        </p>

                        <h5 className='text-lg font-semibold mb-2 mt-6'>Hinweis zur verantwortlichen Stelle</h5>
                        <p>
                            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
                            Website ist:
                        </p>
                        <p className='py-2'>
                            Tobias Bröning<br />
                            Bahnhofstraße 56<br />
                            63538 Großkrotzenburg
                        </p>
                        <p className='py-2'>
                            Telefon: 0160/2348816<br />E-Mail: info@tbvisualarts.com
                        </p>
                        <p>
                            Verantwortliche Stelle ist die natürliche oder juristische Person,
                            die allein oder gemeinsam mit anderen überdie Zwecke und Mittel
                            der Verarbeitung von personenbezogenen Daten (z. B. Namen,
                            E-Mail-Adressen o. Ä.)entscheidet.
                        </p>

                        <h5 className='text-lg font-semibold mb-2 mt-6'>Speicherdauer</h5>
                        <p>
                            Soweit innerhalb dieser Datenschutzerklärung keine speziellere
                            Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen
                            Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt.
                            Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine
                            Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
                            gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für
                            die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer-
                            oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten
                            Fall erfolgt die Löschung nach Fortfall dieser Gründe.
                        </p>

                        <h5 className='text-lg font-semibold mb-2 mt-6'>
                            Widerruf Ihrer Einwilligung zur Datenverarbeitung
                        </h5>
                        <p>
                            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
                            Einwilligung möglich. Sie können eine bereits erteilte
                            Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum
                            Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
                            unberührt.
                        </p>

                        <h5 className='text-lg font-semibold mb-2 mt-6'>
                            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen
                            sowie gegen Direktwerbung (Art. 21 DSGVO)
                        </h5>
                        <p>
                            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
                            ODER F DSGVOERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
                            DIE SICH AUS IHRER BESONDERENSITUATION ERGEBEN, GEGEN DIE
                            VERARBEITUNG IHRER PERSONENBEZOGENEN DATENWIDERSPRUCH EINZULEGEN;
                            DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTESPROFILING.
                            DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG
                            BERUHT,ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE
                            WIDERSPRUCH EINLEGEN,WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN
                            DATEN NICHT MEHR VERARBEITEN, ESSEI DENN, WIR KÖNNEN ZWINGENDE
                            SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNGNACHWEISEN, DIE IHRE
                            INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIEVERARBEITUNG
                            DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG
                            VONRECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
                        </p>
                        <p>
                            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG
                            ZU BETREIBEN,SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN
                            DIE VERARBEITUNG SIEBETREFFENDER PERSONENBEZOGENER DATEN ZUM
                            ZWECKE DERARTIGER WERBUNGEINZULEGEN; DIES GILT AUCH FÜR DAS
                            PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG INVERBINDUNG STEHT.
                            WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN
                            DATENANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG
                            VERWENDET (WIDERSPRUCHNACH ART. 21 ABS. 2 DSGVO).
                        </p>

                        <h5 className='text-lg font-semibold mb-2 mt-6'>
                            Beschwerderecht bei der zuständigen Aufsichtsbehörde
                        </h5>
                        <p>
                            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
                            Beschwerderecht bei einerAufsichtsbehörde, insbesondere in dem
                            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres
                            Arbeitsplatzesoder des Orts des mutmaßlichen Verstoßes zu. Das
                            Beschwerderecht besteht unbeschadet
                            anderweitigerverwaltungsrechtlicher oder gerichtlicher
                            Rechtsbehelfe.
                        </p>

                        <h5 className='text-lg font-semibold mb-2 mt-6'>Recht auf Datenübertragbarkeit</h5>
                        <p>
                            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
                            Einwilligung oder in Erfüllung eines Vertrags automatisiert
                            verarbeiten, an sich oder an einen Dritten in einem gängigen,
                            maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die
                            direkte Übertragung der Daten an einen anderen Verantwortlichen
                            verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                        </p>

                        <h5 className='text-lg font-semibold mb-2 mt-6'>Auskunft, Löschung und Berichtigung</h5>
                        <p>
                            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
                            jederzeit das Recht auf unentgeltlicheAuskunft über Ihre
                            gespeicherten personenbezogenen Daten, deren Herkunft und
                            Empfänger und denZweck der Datenverarbeitung und ggf. ein Recht
                            auf Berichtigung oder Löschung dieser Daten. Hierzu sowiezu
                            weiteren Fragen zum Thema personenbezogene Daten können Sie sich
                            jederzeit an uns wenden.
                        </p>

                        <h5 className='text-lg font-semibold mb-2 mt-6'>Recht auf Einschränkung der Verarbeitung</h5>
                        <p>
                            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
                            personenbezogenen Daten zu verlangen.Hierzu können Sie sich
                            jederzeit an uns wenden. Das Recht auf Einschränkung der
                            Verarbeitung besteht infolgenden Fällen:
                        </p>
                        <p>
                            - Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                            personenbezogenen Daten bestreiten, benötigen wirin der Regel
                            Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
                            das Recht, dieEinschränkung der Verarbeitung Ihrer
                            personenbezogenen Daten zu verlangen.
                            <br />
                            - Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
                            geschah/geschieht, können Siestatt der Löschung die Einschränkung
                            der Datenverarbeitung verlangen.
                            <br />
                            - Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
                            sie jedoch zur Ausübung,Verteidigung oder Geltendmachung von
                            Rechtsansprüchen benötigen, haben Sie das Recht, statt derLöschung
                            die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
                            zu verlangen.
                            <br />
                            - Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
                            haben, muss eine Abwägung zwischenIhren und unseren Interessen
                            vorgenommen werden. Solange noch nicht feststeht, wessen
                            Interessenüberwiegen, haben Sie das Recht, die Einschränkung der
                            Verarbeitung Ihrer personenbezogenen Datenzu verlangen.
                        </p>
                        <p>
                            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
                            eingeschränkt haben, dürfen diese Daten – vonihrer Speicherung
                            abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
                            Ausübung oderVerteidigung von Rechtsansprüchen oder zum Schutz der
                            Rechte einer anderen natürlichen oderjuristischen Person oder aus
                            Gründen eines wichtigen öffentlichen Interesses der Europäischen
                            Union odereines Mitgliedstaats verarbeitet werden.
                        </p>

                        <h2 className='text-xl font-semibold mb-4 mt-12'>4. Datenerfassung auf dieser Website</h2>
                        <h5 className='text-lg font-semibold mb-2 mt-6'>Kontaktformular</h5>
                        <p>
                            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
                            Ihre Angaben aus demAnfrageformular inklusive der von Ihnen dort
                            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrageund für den
                            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben
                            wir nicht ohne Ihre Einwilligung weiter.
                        </p>
                        <p>
                            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
                            Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mitder Erfüllung eines
                            Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
                            Maßnahmenerforderlich ist. In allen übrigen Fällen beruht die
                            Verarbeitung auf unserem berechtigten Interesse an dereffektiven
                            Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
                            DSGVO) oder auf IhrerEinwilligung (Art. 6 Abs. 1 lit. a DSGVO)
                            sofern diese abgefragt wurde.
                        </p>
                        <p>
                            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
                            uns, bis Sie uns zur Löschungauffordern, Ihre Einwilligung zur
                            Speicherung widerrufen oder der Zweck für die Datenspeicherung
                            entfällt(z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
                            Zwingende gesetzliche Bestimmungen –insbesondere
                            Aufbewahrungsfristen – bleiben unberührt.
                        </p>

                        <h5 className='text-lg font-semibold mb-2 mt-6'>Anfrage per E-Mail, Telefon oder Telefax</h5>
                        <p>
                            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
                            Ihre Anfrage inklusive aller daraushervorgehenden
                            personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung
                            Ihres Anliegensbei uns gespeichert und verarbeitet. Diese Daten
                            geben wir nicht ohne Ihre Einwilligung weiter.
                        </p>
                        <p>
                            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
                            Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mitder Erfüllung eines
                            Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
                            Maßnahmenerforderlich ist. In allen übrigen Fällen beruht die
                            Verarbeitung auf unserem berechtigten Interesse an dereffektiven
                            Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
                            DSGVO) oder auf IhrerEinwilligung (Art. 6 Abs. 1 lit. a DSGVO)
                            sofern diese abgefragt wurde.
                        </p>
                        <p>
                            Die von Ihnen an uns per Kontaktanfragen übersandten Daten
                            verbleiben bei uns, bis Sie uns zur Löschungauffordern, Ihre
                            Einwilligung zur Speicherung widerrufen oder der Zweck für die
                            Datenspeicherung entfällt(z. B. nach abgeschlossener Bearbeitung
                            Ihres Anliegens). Zwingende gesetzliche Bestimmungen –insbesondere
                            gesetzliche Aufbewahrungsfristen – bleiben unberührt.
                        </p>

                        <p className='pt-12'>Quelle: <a href='https://www.e-recht24.de' className='underline'>eRecht24</a></p>
                    </div>
                </div>
            </div>
        </Transition>
    );
}

export default Datenschutz;
