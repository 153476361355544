import {React, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight, faAnglesLeft} from "@fortawesome/free-solid-svg-icons";
import {faMessage as farMessage} from "@fortawesome/free-regular-svg-icons";


function Service(props) {
    const [images, setImages] = useState([])
    const [service, setService] = useState(undefined)
    const [serviceInitial, setServiceInitial] = useState(undefined)
    const [serviceNext, setServiceNext] = useState(undefined)

    useEffect(() => {
        if (images.length === 0) setImages(props.images)
    }, [props])

    let navigate = useNavigate();
    useEffect(() => {
        const serviceIndex = props.services.map(i => i.uuid).indexOf(props.uuid)
        //console.log(serviceIndex)
        if (props.services.length) {
            if (serviceIndex === -1) navigate('../..')

            if (serviceIndex > 0) setServiceInitial(props.services[serviceIndex-1].uuid);
            else setServiceInitial(undefined)

            if (serviceIndex < props.services.length-1) setServiceNext(props.services[serviceIndex+1].uuid);
            else setServiceNext(undefined)
        }
        setService(props.services[serviceIndex])
    }, [props, navigate])

    return (
        <section className='Service fixed top-0 z-20 bg-grey-dark h-screen w-screen'>
            {
                (props.images.length && service !== undefined) ?
                <LazyLoadImage key='hero_img' alt=''
                               placeholderSrc={'/images/' + props.images.find(image => image.uuid === service.backdrop).preview.mid}
                               src={'/images/' + props.images.find(image => image.uuid === service.backdrop).preview.large}
                               effect='none' className='object-cover h-screen w-screen'
                               wrapperClassName='absolute top-0 bg-cover bg-center h-screen w-screen'
                /> : ''
            }
            {
                service !== undefined ?
                <div className='relative flex flex-col items-center bg-gradient-to-b from-grey-dark/50 via-grey-dark/25 to-grey-dark/50 h-full overflow-auto w-full px-4 xl:px-44 2xl:px-64 py-24 2xl:px-44'>
                    <div className='space-y-6 md:max-w-xl xl:max-w-2xl 2xl:max-w-3xl 3xl:max-w-4xl w-full'>
                        <div className='flex flex-col gap-y-2'>
                            <h1 className='text-3xl font-semibold uppercase w-full'>{service.title}</h1>
                            <Link to='/' className='flex flex-row items-center gap-x-3 hover:text-grey-lightest uppercase w-full'>
                                <FontAwesomeIcon icon={faAnglesLeft} />
                                Home
                            </Link>
                        </div>
                        <div className='relative space-y-4 bg-grey-darker/50 rounded-2xl backdrop-blur-xl w-full p-6 pb-10 md:p-12'>
                            {
                                service.description.map((p, i) =>
                                    <p key={'service_' + service.uuid + '-desc_' + i} className=''>{p}</p>)
                            }
                            <Link to='../contact' className='absolute bottom-0 translate-y-1/2 inline-block bg-offwhite hover:bg-grey-lightest text-grey-dark text-sm 2xl:text-xl font-semibold uppercase rounded-lg mt-8 px-4 py-2'>
                                Kontakt
                                <FontAwesomeIcon icon={farMessage} className='ml-4' />
                            </Link>
                        </div>
                        <div className='grid grid-cols-2 xl:grid-cols-3 gap-2 pt-12'>
                            {
                                service.images.map(imageUuid => {
                                    const image = props.images[props.images.map(i => i.uuid).indexOf(imageUuid)];
                                    if (image !== undefined && image.visible)
                                        return <LazyLoadImage key={image.uuid} alt=''
                                                              placeholderSrc={'/images/' + image.preview.tiny}
                                                              src={'/images/' + image.preview.mid} effect='none'
                                                              className='rounded-xl'
                                                              wrapperClassName='rounded-xl aspect-[3/2]'
                                                              onClick={() => props.openViewer(image.uuid)}
                                        />
                                })
                            }
                        </div>
                    </div>
                    <aside className='text-offwhite text-4xl md:text-5xl xl:text-6xl'>
                        { serviceInitial === undefined ? '' :
                            <Link to={'/service/' + serviceInitial} className='fixed top-1/2 left-0 md:left-12 xl:left-16 z-30 -translate-y-1/2 flex flex-col justify-center md:bg-grey-dark/50 md:hover:bg-grey/75 p-2 md:px-6 md:py-12 rounded-xl duration-300'>
                                <FontAwesomeIcon icon={faAngleLeft} className='' />
                            </Link>
                        }
                        { serviceNext === undefined ? '' :
                            <Link to={'/service/' + serviceNext} className='fixed top-1/2 right-0 md:right-12 xl:right-16 z-30 -translate-y-1/2 flex flex-col justify-center md:bg-grey-dark/50 md:hover:bg-grey/75 p-2 md:px-6 md:py-12 rounded-xl duration-300'>
                                <FontAwesomeIcon icon={faAngleRight} className=''/>
                            </Link>
                        }
                    </aside>
                </div> : ''
            }
        </section>
    );
}

export default Service;
