import {React, useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faAdd, faAngleDown, faAngleUp,
    faArrowRightFromBracket, faArrowsRotate, faCloudArrowUp,
    faEye,
    faEyeSlash, faFloppyDisk, faHome, faRotate, faShuffle, faThumbtack,
    faTrashAlt, faUserShield
} from "@fortawesome/free-solid-svg-icons";

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors, MouseSensor,
} from '@dnd-kit/core';
import {
    arrayMove, horizontalListSortingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import DashboardSortableImage from "./DashboardSortableImage";
import ConfirmModal from "./ConfirmModal";
import InfoModal from "./InfoModal";
import CropperModal from "./CropperModal";


function Dashboard() {
    const dev = false;
    const protocol = 'https'

    const [authorized, setAuthorized] = useState(undefined)

    const [images, setImages] = useState([])
    const [imagesChanged, setImagesChanged] = useState(false)
    const [portfolio, setPortfolio] = useState([])
    const [portfolioRowsPinned, setPortfolioRowsPinned] = useState(false)
    const [portfolioChanged, setPortfolioChanged] = useState(false)
    const [partners, setPartners] = useState([])
    const [partnersChanged, setPartnersChanged] = useState(false)
    const [about, setAbout] = useState([])
    const [aboutChanged, setAboutChanged] = useState(false)
    const [services, setServices] = useState([])
    const [servicesChanged, setServicesChanged] = useState(false)

    const [uploadingImages, setUploadingImages] = useState([]);

    const port = dev ?
        protocol === 'https' ? 443 : 8888
        : protocol === 'https' ? 443 : 80
    const subPath = dev ? '' : '/server';

    useEffect(() => {
        console.log('[Dashboard] updated.')
        fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/login.php', { credentials:"include" })
            .then(response => response.text())
            .then(data => setAuthorized(data === 'authorized'));

        loadAll();

        document.addEventListener("keydown", inputDetector, false);
        return () => { document.removeEventListener("keydown", inputDetector, false) };
    }, [])

    const loadAll = () => {
        fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/get_images.php')
            .then(response => response.json())
            .then(data => setImages(data));

        fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/get_portfolio.php')
            .then(response => response.json())
            .then(data => {
                if (data.length) setPortfolio([...data, {'uuid': 0, 'visible': true, 'pinned': false, 'images': [], 'backdrops': {'hero': null, 'partners': null, 'subpages': null, 'footer': null}}])
                else setPortfolio([{'uuid': 0, 'visible': true, 'pinned': false, 'images': [], 'backdrops': {'hero': null, 'partners': null, 'subpages': null, 'footer': null}}])
                setPortfolioRowsPinned(getRowsPinned(data))
            });

        fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/get_partners.php')
            .then(response => response.json())
            .then(data => setPartners(data));

        fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/get_about.php')
            .then(response => response.json())
            .then(data => setAbout(data));

        fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/get_services.php')
            .then(response => response.json())
            .then(data => setServices(data));
    }

    const reloadImages = () => {
        fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/get_images.php')
            .then(response => response.json())
            .then(data => setImages(data));
    }

    useEffect(() => {
        if (imagesChanged) saveImages();
    }, [imagesChanged])

    const btnSave = useRef(null)
    const inputDetector = (e) => {
        if (e.ctrlKey || e.metaKey) {
            switch (String.fromCharCode(e.which).toLowerCase()) {
                case 's':
                    e.preventDefault();
                    btnSave.current.click();
                    break;
                default:
            }
        }
        //console.log(e.key)
    }

    const uploadingImagesUpdaterRef = useRef(null)

    const handleSubmit = async (e) => {
        e.preventDefault()
        await fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/login.php', {method: 'POST', body: new FormData(e.target), credentials:"include"})
            .then(response => response.text())
            .then(data => setAuthorized(data === 'authorized'));
    }

    const handleAddImage = (e, aspect) => {
        if (e.target.files.length>0) setCropper({'visible': true, 'aspect': aspect, src: URL.createObjectURL(new Blob(e.target.files)), 'callback': (formData, blob)=>addImage(formData, blob), 'param': new FormData(e.target.parentElement)})
    }
    const addImage = async (formData, blob) => {
        const newUploadingImage = {
            'uuid': ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)),
            'progress': 0,
            'blob': blob,
            'section': formData.get('section')
        }
        setUploadingImages(u => [...u, newUploadingImage])

        // request below refreshes the page during development, due to changes in the public folder!! no worries, getting a production build solves the issue.
        const ajax = new XMLHttpRequest();
        ajax.upload.addEventListener("progress", (e)=>handleUploadImageProgress(e, newUploadingImage.uuid), false);
        ajax.open('POST', protocol + '://' + window.location.hostname + ':' + port + subPath + '/add_image.php');
        ajax.withCredentials = true;
        ajax.send(formData);
        /* Response not really recreatable with XmlHttpRequest
        await fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/add_image.php', {method: 'POST', body: formData, credentials:"include"})
            .then(response => response.text())
            .then(data => {
                if (data === 'done') reloadImages()
                else if (data === 'error') setInfo({'visible': true, 'title': 'Fehler', 'text': 'Hochladen fehlgeschlagen.'});
            });*/
    }

    const handleUploadImageProgress = (e, uploadingImageUuid) => {
        uploadingImagesUpdaterRef.current.value = JSON.stringify({
            'uuid': uploadingImageUuid,
            'progress': Math.round((e.loaded / e.total) * 100)
        })
        uploadingImagesUpdaterRef.current.click()
    }
    const uploadImageProgress = (e) => {
        const change = JSON.parse(e.target.value);
        const index = uploadingImages.map(i => i.uuid).indexOf(change.uuid);
        const newUploadingImages = [...uploadingImages]
        newUploadingImages[index].progress = change.progress;
        setUploadingImages(newUploadingImages)
        if (change.progress === 100) loadAll()
    }

    const handlePartnersChange = (e, partnerUuid) => {
        const index = partners.map(p => p.uuid).indexOf(partnerUuid);
        if (index<0) return;
        const newPartners = [...partners]
        newPartners[index].url = e.target.value;
        setPartners(newPartners)
        setPartnersChanged(true)
    }

    const saveAll = async () => {
        //const setImagesSucc = await saveImages()
        const setPortfolioSucc = await savePortfolio()
        const setPartnersSucc = await savePartners()
        const setAboutSucc = await saveAbout()
        const setServicesSucc = await saveServices()

        let unSucc = []

        //if (setImagesSucc === false) unSucc.push('set_images')
        if (setPortfolioSucc === false) unSucc.push('set_portfolio')
        if (setPartnersSucc === false) unSucc.push('set_partners')
        if (setAboutSucc === false) unSucc.push('set_about')
        if (setServicesSucc === false) unSucc.push('set_services')

        if (!unSucc.length) setInfo({'visible': true, 'title': 'Gespeichert', 'text': 'Alle Änderungen wurden erfolgreich gesichert.'})
        else setInfo({'visible': true, 'title': 'Fehler', 'text': 'Beim Speichern der Änderungen gab es ein Problem. [' + unSucc.join(", ") + ']'})
    }

    const saveImages = async () => {
        if (!imagesChanged) return;
        let succ = false;
        await fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/set_images.php', {method: 'POST', body: JSON.stringify(images), credentials:"include"})
            .then(response => response.text())
            .then(data => data === 'done' ? succ = true : false)
        if (succ) setImagesChanged(false)
        return succ
    }

    const savePortfolio = async () => {
        if (!portfolioChanged) return;
        let succ = false;
        await fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/set_portfolio.php', {method: 'POST', body: JSON.stringify(portfolio.slice(0,-1)), credentials:"include"})
            .then(response => response.text())
            .then(data => data === 'done' ? succ = true : false);
        if (succ) setPortfolioChanged(false)
        return succ
    }

    const savePartners = async () => {
        if (!partnersChanged) return;
        let succ = false;
        await fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/set_partners.php', {method: 'POST', body: JSON.stringify(partners), credentials:"include"})
            .then(response => response.text())
            .then(data => data === 'done' ? succ = true : false);
        if (succ) setPartnersChanged(false)
        return succ
    }

    const saveAbout = async () => {
        if (!aboutChanged) return;
        let succ = false;
        await fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/set_about.php', {method: 'POST', body: JSON.stringify(about), credentials:"include"})
            .then(response => response.text())
            .then(data => data === 'done' ? succ = true : false);
        if (succ) setAboutChanged(false)
        return succ
    }

    const saveServices = async () => {
        if (!servicesChanged) return;
        let succ = false;
        await fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/set_services.php', {method: 'POST', body: JSON.stringify(services), credentials:"include"})
            .then(response => response.text())
            .then(data => data === 'done' ? succ = true : false);
        if (succ) setServicesChanged(false)
        return succ
    }

    const handleDeleteRow = (uuid) => setConfirm({'visible': true, 'title': 'Zeile löschen', 'text': 'Möchtest du die Zeile wirklich löschen?', 'callback': uuid=>deleteRow(uuid), 'param': uuid});
    const deleteRow = async (uuid) => {
        const index = portfolio.map(row => row.uuid).indexOf(uuid);
        const newPortfolio = [...portfolio];
        newPortfolio.splice(index, 1);
        setPortfolio(newPortfolio);
        const delRowForm = new FormData();
        delRowForm.append('row', uuid);
        await fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/del_row.php', {method: 'POST', body: delRowForm, credentials:"include"})
            .then(response => response.text())
            .then(data => {
                if (data === 'done') setInfo({'visible': true, 'title': 'Gelöscht', 'text': 'Die Reihe wurde erfolgreich gelöscht.'});
                else setInfo({'visible': true, 'title': 'Fehler', 'text': 'Löschen fehlgeschlagen.'})
            })
    }

    const handleDeleteImage = (section, rowUuid, imageUuid) => {
        const delImageForm = new FormData();
        delImageForm.append('section', section);
        if (rowUuid !== undefined) delImageForm.append('row', rowUuid);
        delImageForm.append('image', imageUuid);
        setConfirm({'visible': true, 'title': 'Bild löschen', 'text': 'Möchtest du dieses Bild wirklich löschen?', 'callback': formData=>deleteImage(formData), 'param': delImageForm})
    }
    const deleteImage = async (formData) => {
        await fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/del_image.php', {method: 'POST', body: formData, credentials:"include"})
            .then(response => response.text())
            .then(data => {
                if (data === 'done') reloadImages()
                else if (data === 'error') setInfo({'visible': true, 'title': 'Fehler', 'text': 'Löschen fehlgeschlagen.'});
            });
    }

    const toggleRowVisibility = (uuid) => {
        const index = portfolio.map(row => row.uuid).indexOf(uuid);
        const newPortfolio = [...portfolio];
        newPortfolio[index].visible = !newPortfolio[index].visible;
        // console.log(newPortfolio[index].visible);
        setPortfolio(newPortfolio);
        setPortfolioChanged(true);
    }

    const toggleImageVisibility = (uuid) => {
        const index = images.map(image => image.uuid).indexOf(uuid);
        const newImages = [...images];
        newImages[index].visible = !newImages[index].visible;
        //console.log(newImages[index].visible);
        setImages(newImages);
        setImagesChanged(true);
    }

    const toggleRowPinned = (uuid) => {
        const newPortfolio = [...portfolio];
        newPortfolio.forEach(row => row.pinned = row.uuid === uuid ? !row.pinned : false);
        // console.log(newPortfolio[index].pinned);
        setPortfolio(newPortfolio);
        setPortfolioRowsPinned(getRowsPinned(portfolio))
        setPortfolioChanged(true);
    }

    const toggleRowsPinned = (e) => { // pins all rows (no shuffle)
        const state = !portfolioRowsPinned
        const newPortfolio = [...portfolio];
        newPortfolio.forEach(row => row.pinned = state);
        setPortfolio(newPortfolio);
        setPortfolioRowsPinned(state)
        setPortfolioChanged(true);
    }

    const getRowsPinned = (a) => {
        let allPinned = true;
        a.forEach(row => allPinned = row.pinned ? allPinned : false);
        return allPinned;
    }

    const mvRowUp = (uuid) => {
        const index = portfolio.map(row => row.uuid).indexOf(uuid);
        if (index < 1) return;
        const newPortfolio = [...portfolio];
        if (!portfolioRowsPinned) newPortfolio[index].pinned = false;
        newPortfolio[index-1] = newPortfolio[index];
        newPortfolio[index] = {...portfolio[index-1]};
        setPortfolio(newPortfolio);
        setPortfolioChanged(true);
    }
    const mvRowDown = (uuid) => {
        const index = portfolio.map(row => row.uuid).indexOf(uuid);
        if (index > portfolio.length-2-1) return;
        const newPortfolio = [...portfolio];
        if (!portfolioRowsPinned) newPortfolio[index].pinned = false;
        newPortfolio[index+1] = newPortfolio[index];
        newPortfolio[index] = {...portfolio[index+1]};
        setPortfolio(newPortfolio);
        setPortfolioChanged(true);
    }

    const setPortfolioBackdrop = (key, rowUuid, imageUuid) => {
        const index = portfolio.map(row => row.uuid).indexOf(rowUuid);
        const newPortfolio = [...portfolio];
        newPortfolio[index].backdrops[key] = imageUuid;
        setPortfolio(newPortfolio);
        setPortfolioChanged(true);
    }

    const setServicesBackdrop = (rowUuid, imageUuid) => {
        const index = services.map(row => row.uuid).indexOf(rowUuid);
        const newServices = [...services];
        newServices[index].backdrop = imageUuid;
        setServices(newServices);
        setServicesChanged(true);
    }



    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {distance: 10}
        }),
        useSensor(MouseSensor, {
            activationConstraint: {distance: 10}
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
     );

    function handleDragEndPortfolio(e, rowUuid) {
        const {active, over} = e;
        // console.log(e.active.data.current.sortable.items)
        if (active.id !== over.id) {
            setPortfolio(portfolio => {
                const index = portfolio.map(row => row.uuid).indexOf(rowUuid);
                const newPortfolio = [...portfolio];
                const oldIndex = portfolio[index]['images'].indexOf(active.id);
                const newIndex = portfolio[index]['images'].indexOf(over.id);
                newPortfolio[index]['images'] = arrayMove(portfolio[index]['images'], oldIndex, newIndex);
                setPortfolioChanged(true);
                return newPortfolio;
            })
        }
    }

    function handleDragEndPartners(e) {
        const {active, over} = e;
        if (active.id !== over.id) {
            setPartners(partners => {
                const oldIndex = partners.map(partner => partner.logo).indexOf(active.id);
                const newIndex = partners.map(partner => partner.logo).indexOf(over.id);
                const newPartners = arrayMove(partners, oldIndex, newIndex);
                setPartnersChanged(true);
                return newPartners;
            })
        }
    }

    function handleDragEndAbout(e) {
        const {active, over} = e;
        if (active.id !== over.id) {
            setAbout(about => {
                const oldIndex = about.map(abou => abou.image).indexOf(active.id);
                const newIndex = about.map(abou => abou.image).indexOf(over.id);
                const newAbout = arrayMove(about, oldIndex, newIndex);
                setAboutChanged(true);
                return newAbout;
            })
        }
    }

    function handleDragEndServices(e, rowUuid) {
        const {active, over} = e;
        // console.log(e.active.data.current.sortable.items)
        if (active.id !== over.id) {
            setServices(service => {
                const index = service.map(row => row.uuid).indexOf(rowUuid);
                const newServices = [...service];
                const oldIndex = service[index]['images'].indexOf(active.id);
                const newIndex = service[index]['images'].indexOf(over.id);
                newServices[index]['images'] = arrayMove(service[index]['images'], oldIndex, newIndex);
                setServicesChanged(true);
                return newServices;
            })
        }
    }


    const [confirm, setConfirm] = useState({'visible': false, 'title': '', 'text': '', 'callback': ()=>{}, 'param': ''});
    const resetConfirm = () => setConfirm({'visible': false, 'title': '', 'text': '', 'callback': ()=>{}, 'param': ''});
    const [cropper, setCropper] = useState({'visible': false, 'aspect': 0, 'src': '', 'callback': ()=>{}, 'param': new FormData()});
    const resetCropper = () => setCropper({'visible': false, 'aspect': 0, 'src': '', 'callback': ()=>{}, 'param':  new FormData()});
    const [info, setInfo] = useState({'visible': false, 'title': '', 'text': ''});
    const resetInfo = () => setInfo({'visible': false, 'title': '', 'text': ''});

    return (
        <div className='Dashboard flex flex-col bg-grey-dark text-offwhite font-montserrat p-8 min-h-screen'>
            <header className='flex flex-row justify-between items-center mb-16'>
                <img src='/logo.svg' alt='logo' className='w-24' />
                <h1 className='text-4xl font-bold uppercase'>Dashboard</h1>
                { authorized ?
                    <form onSubmit={handleSubmit} className='flex flex-row space-x-4'>
                        <button type='button' className='text-3xl' onClick={loadAll}><FontAwesomeIcon icon={faArrowsRotate} /></button>
                        <Link to='/' className='flex flex-row items-center text-lg'><FontAwesomeIcon icon={faHome} className='text-3xl' /></Link>
                        <input type="hidden" name="logout" />
                        <input type="submit" id='logout' value='' className='hidden' />
                        <label htmlFor='logout'><FontAwesomeIcon icon={faArrowRightFromBracket} className='text-3xl px-4' /></label>
                    </form>
                    : <div className='placeholder w-24'> </div>
                }
            </header>
            {
                authorized === undefined ?
                <div className='flex flex-col grow justify-center items-center space-y-4 pb-72'>
                    <FontAwesomeIcon icon={faUserShield} className='text-6xl' />
                    <p className='flex flex-row items-center space-x-2'>
                        <FontAwesomeIcon icon={faRotate} className='animate-spin text-sm' />
                        <span>authorizing...</span>
                    </p>
                </div>
                : authorized ? <main className='flex flex-col space-y-16'>
                    {
                        uploadingImages.length > 0 ?
                        <section>
                            <div className='flex flex-row items-center pb-6'>
                                <h2 className='text-2xl font-semibold uppercase'>Uploading<FontAwesomeIcon icon={faCloudArrowUp} className='text-grey-lightest ml-6' /></h2>
                            </div>
                            <div className='bg-grey-darker p-4'>
                                <div className='flex flex-row space-x-4 select-none overflow-x-auto'>
                                    {
                                        uploadingImages.map(image =>
                                            <div key={'uploading_image' + image.uuid} className='shrink-0 text-lg aspect-[3/2] w-52'>
                                                <img src={image.blob} alt='' className='bg-grey object-cover aspect-[3/2] w-full' />
                                                <div className='flex flex-row justify-between'>
                                                    <span>{image.section}</span>
                                                    { image.progress < 100 ? <span>{image.progress}%</span> : <span>finished</span> }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <input ref={uploadingImagesUpdaterRef} type='hidden' onClick={uploadImageProgress} defaultValue={'{}'} />
                        </section> : ''
                    }
                    <button ref={btnSave} className='hidden' onClick={saveAll} />
                    <section>
                        <div className='flex flex-row justify-between items-center pb-6'>
                            <h2 className='text-2xl font-semibold uppercase'>
                                Portfolio
                                <FontAwesomeIcon icon={faShuffle} className={`text-grey-lightest ml-6 ${portfolioRowsPinned && 'opacity-20'}`} onClick={toggleRowsPinned} />
                            </h2>
                            { portfolioChanged &&  <span className="text-xl text-primary">ungespeicherte Änderungen</span> }
                            <button onClick={savePortfolio} className='flex flex-row items-center text-lg'>speichern<FontAwesomeIcon icon={faFloppyDisk} className='text-2xl px-4' /></button>
                        </div>
                        <div className='flex flex-col divide-y-4 divide-grey-dark bg-grey-darker'>
                            { portfolio.map(row =>
                                <div key={row.uuid} className='flex flex-row p-4'>
                                    { portfolio.length > 1 && <>
                                        {
                                            <div className={`flex flex-col justify-center space-y-8 w-14 ${row.uuid === portfolio[portfolio.length-1].uuid && 'hidden'}`}>
                                                {row.uuid === portfolio[0].uuid ?
                                                    <FontAwesomeIcon className={`text-2xl rotate-45 ${!row.pinned && 'opacity-20'}`} icon={faThumbtack} onClick={() => toggleRowPinned(row.uuid)}/>
                                                    : <FontAwesomeIcon className='text-2xl' icon={faAngleUp} onClick={() => mvRowUp(row.uuid)}/>
                                                }
                                                <FontAwesomeIcon className={`text-2xl ${row.uuid === portfolio[portfolio.length-2].uuid && 'opacity-20'}`} icon={faAngleDown} onClick={()=>mvRowDown(row.uuid)} />
                                            </div>
                                        }
                                        <div className={`flex flex-col justify-center space-y-8 w-14 ${row.uuid === portfolio[portfolio.length-1].uuid && 'hidden'}`}>
                                            <FontAwesomeIcon className='text-2xl' icon={row.visible ? faEyeSlash : faEye} onClick={()=>toggleRowVisibility(row.uuid)} />
                                            <FontAwesomeIcon className='text-2xl text-red-500' icon={faTrashAlt} onClick={()=>handleDeleteRow(row.uuid)} />
                                        </div>
                                    </> }
                                    <div className={`flex flex-row space-x-4 w-screen select-none overflow-x-auto px-8 ${row.uuid === portfolio[portfolio.length-1].uuid ? 'px-0' : ''}`}>
                                        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={(e)=>handleDragEndPortfolio(e, row.uuid)}>
                                            <SortableContext items={row.images} strategy={horizontalListSortingStrategy}>
                                                {
                                                    row.images.map(imageUuid => {
                                                        const image = images[images.map(i => i.uuid).indexOf(imageUuid)];
                                                        if (image !== undefined)
                                                            return <DashboardSortableImage key={image.uuid}
                                                                                           id={image.uuid}
                                                                                           image={image}
                                                                                           row={row}
                                                                                           toggleVis={toggleImageVisibility}
                                                                                           del={()=>handleDeleteImage('portfolio', row.uuid, image.uuid)}
                                                                                           setBackdrop={setPortfolioBackdrop}
                                                            />
                                                        else return ''
                                                    })
                                                }
                                            </SortableContext>
                                        </DndContext>
                                        <form className={`shrink-0 text-xl ${row.uuid !== portfolio[portfolio.length-1].uuid && 'bg-grey text-2xl hover:text-3xl aspect-[3/2] w-52'}`}>
                                            <label htmlFor={'file_' + row.uuid} className='flex flex-col justify-center text-center h-full'>
                                                <div>
                                                    <FontAwesomeIcon className='px-4' icon={faAdd} />
                                                    {row.uuid === portfolio[portfolio.length-1].uuid && 'Neue Zeile erstellen'}
                                                </div>
                                            </label>
                                            <input type='file' accept='image/*' name='file' id={'file_' + row.uuid} className='hidden' onChange={(e)=>handleAddImage(e, 3/2)} />
                                            <input type='hidden' name='section' value='portfolio' />
                                            <input type='hidden' name='row' value={row.uuid} />
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                    </section>
                    <section>
                        <div className='flex flex-row justify-between items-center pb-6'>
                            <h2 className='text-2xl font-semibold uppercase'>Partners</h2>
                            { partnersChanged &&  <span className="text-xl text-primary">ungespeicherte Änderungen</span> }
                            <button onClick={savePartners} className='flex flex-row items-center text-lg'>speichern<FontAwesomeIcon icon={faFloppyDisk} className='text-2xl px-4' /></button>
                        </div>
                        <div className='flex flex-col divide-y-4 divide-grey-dark bg-grey-darker'>
                            <div className='flex flex-row space-x-4 select-none overflow-x-auto p-4'>
                                { !!partners.length &&
                                    <DndContext sensors={sensors} collisionDetection={closestCenter}
                                                onDragEnd={(e) => handleDragEndPartners(e)}>
                                        <SortableContext items={partners.map(partner => partner.logo)}
                                                         strategy={horizontalListSortingStrategy}>
                                            {
                                                partners.map(partner => {
                                                    const image = images[images.map(i => i.uuid).indexOf(partner.logo)];
                                                    if (image !== undefined)
                                                        return <DashboardSortableImage key={image.uuid}
                                                                                       id={image.uuid}
                                                                                       image={image}
                                                                                       toggleVis={toggleImageVisibility}
                                                                                       del={()=>handleDeleteImage('partners', undefined, image.uuid)}
                                                        />
                                                    else return ''
                                                })
                                            }
                                        </SortableContext>
                                    </DndContext>
                                }
                                <form className='shrink-0 bg-grey text-2xl hover:text-3xl aspect-[3/2] w-52'>
                                    <label htmlFor={'file_partners'} className='flex flex-col justify-center text-center h-full'>
                                        <div>
                                            <FontAwesomeIcon className='px-4' icon={faAdd} />
                                        </div>
                                    </label>
                                    <input type='file' accept='image/*' name='file' id={'file_partners'} className='hidden' onChange={(e)=>handleAddImage(e, 0)} />
                                    <input type='hidden' name='section' value='partners' />
                                </form>
                            </div>
                            <div className='p-4'>
                                <h3 className='text-xl font-semibold pb-4'>URLs</h3>
                                <ol className='flex flex-col space-y-2 list-decimal list-inside'>
                                    { partners.map(partner => <li key={'partner_url_' + partner.uuid}><input type='url' defaultValue={partner.url} className='bg-grey w-96 ml-2 p-2' onChange={(e) => handlePartnersChange(e, partner.uuid)} /></li> ) }
                                </ol>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className='flex flex-row justify-between items-center pb-6'>
                            <h2 className='text-2xl font-semibold uppercase'>About<FontAwesomeIcon icon={faShuffle} className='text-grey-lightest ml-6' /></h2>
                            { aboutChanged && <span className="text-xl text-primary">ungespeicherte Änderungen</span> }
                            <button onClick={saveAbout} className='flex flex-row items-center text-lg'>speichern<FontAwesomeIcon icon={faFloppyDisk} className='text-2xl px-4' /></button>
                        </div>
                        <div className='bg-grey-darker p-4'>
                            <div className='flex flex-row space-x-4 select-none overflow-x-auto'>
                                { !!about.length &&
                                    <DndContext sensors={sensors} collisionDetection={closestCenter}
                                                onDragEnd={(e) => handleDragEndAbout(e)}>
                                        <SortableContext items={about.map(abou => abou.image)} strategy={horizontalListSortingStrategy}>
                                            {
                                                about.map(abou => {
                                                    const image = images[images.map(i => i.uuid).indexOf(abou.image)];
                                                    if (image !== undefined)
                                                        return <DashboardSortableImage key={image.uuid}
                                                                                       id={image.uuid}
                                                                                       image={image}
                                                                                       toggleVis={toggleImageVisibility}
                                                                                       del={()=>handleDeleteImage('about', undefined, image.uuid)}
                                                        />
                                                    else return ''
                                                })
                                            }
                                        </SortableContext>
                                    </DndContext>
                                }
                                <form className='shrink-0 bg-grey text-2xl hover:text-3xl aspect-[3/4] w-52'>
                                    <label htmlFor={'file_about'} className='flex flex-col justify-center text-center h-full'>
                                        <div>
                                            <FontAwesomeIcon className='px-4' icon={faAdd} />
                                        </div>
                                    </label>
                                    <input type='file' accept='image/*' name='file' id={'file_about'} className='hidden' onChange={(e)=>handleAddImage(e, 3/4)} />
                                    <input type='hidden' name='section' value='about' />
                                </form>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className='flex flex-row justify-between items-center pb-6'>
                            <h2 className='text-2xl font-semibold uppercase'>Services</h2>
                            { servicesChanged &&  <span className="text-xl text-primary">ungespeicherte Änderungen</span> }
                            <button onClick={saveServices} className='flex flex-row items-center text-lg'>speichern<FontAwesomeIcon icon={faFloppyDisk} className='text-2xl px-4' /></button>
                        </div>
                        <div className='flex flex-col divide-y-4 divide-grey-dark bg-grey-darker'>
                            { services.map(row =>
                                <div key={row.uuid} className='flex flex-row p-4'>
                                    {/*
                                    <div className='flex flex-col justify-center space-y-8 w-14'>
                                        <FontAwesomeIcon className='text-2xl' icon={row.visible ? faEyeSlash : faEye} onClick={()=>toggleRowVisibility(row.uuid)} />
                                        <FontAwesomeIcon className='text-2xl text-red-500' icon={faTrashAlt} onClick={()=>handleDeleteRow(row.uuid)} />
                                    </div>
                                    */}
                                    <div className={`flex flex-row space-x-4 w-screen select-none overflow-x-auto`}>
                                        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={(e)=>handleDragEndServices(e, row.uuid)}>
                                            <SortableContext items={row.images} strategy={horizontalListSortingStrategy}>
                                                {
                                                    row.images.map(imageUuid => {
                                                        const image = images[images.map(i => i.uuid).indexOf(imageUuid)];
                                                        if (image !== undefined)
                                                            return <DashboardSortableImage key={image.uuid}
                                                                                           id={image.uuid}
                                                                                           image={image}
                                                                                           row={row}
                                                                                           toggleVis={toggleImageVisibility}
                                                                                           del={()=>handleDeleteImage('services', row.uuid, image.uuid)}
                                                                                           setBackdrop={setServicesBackdrop}
                                                            />
                                                        else return ''
                                                    })
                                                }
                                            </SortableContext>
                                        </DndContext>
                                        <form className='shrink-0 bg-grey text-2xl hover:text-3xl aspect-[3/2] w-52'>
                                            <label htmlFor={'file_' + row.uuid} className='flex flex-col justify-center text-center h-full'>
                                                <div>
                                                    <FontAwesomeIcon className='px-4' icon={faAdd} />
                                                </div>
                                            </label>
                                            <input type='file' accept='image/*' name='file' id={'file_' + row.uuid} className='hidden' onChange={(e)=>handleAddImage(e, 3/2)} />
                                            <input type='hidden' name='section' value='services' />
                                            <input type='hidden' name='row' value={row.uuid} />
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                    </section>
                    <div>
                        <ConfirmModal callback={confirm.callback} param={confirm.param} visible={confirm.visible} title={confirm.title} text={confirm.text} res={resetConfirm} />
                        <CropperModal callback={cropper.callback} param={cropper.param} visible={cropper.visible} aspect={cropper.aspect} src={cropper.src} res={resetCropper} />
                        <InfoModal callback={info.callback} visible={info.visible} title={info.title} text={info.text} res={resetInfo} />
                    </div>
                </main> :
                <div className='flex flex-row grow justify-center items-center pb-72'>
                    <form onSubmit={handleSubmit} className='flex flex-col space-y-4 bg-grey text-xl p-8'>
                        <input type="text" name="user" defaultValue='' placeholder="User" className='bg-offwhite text-black rounded p-2' />
                        <input type="password" name="password" defaultValue='' placeholder="Passwort" className='bg-offwhite text-black rounded p-2' />
                        <input type="submit" value="Login" className='bg-grey-lighter rounded mt-4 p-2' />
                    </form>
                </div>
            }
        </div>
    );
}

export default Dashboard;
