import {React, useState, useEffect, useCallback, useRef} from 'react';

function InfoModal(props) {
    const btnOkay = useRef(null);

    useEffect(() => {
        setVisible(props.visible);
        if (props.visible) setTimeout(() => btnOkay.current.focus(), 0)
    }, [props.visible])

    const [visible, setVisible] = useState(false);

    const close = (e) => !e.defaultPrevented && props.res();

    return (
        <section className={`fixed z-50 top-0 left-0 flex flex-col justify-center items-center bg-black/75 h-screen w-screen ${!visible && 'hidden'}`} onClick={close}>
            <div className='flex flex-col gap-y-6 bg-grey-darker rounded-xl p-8' onClick={(e)=>{e.preventDefault()}}>
                <h2 className='text-xl text-grey-lightest font-semibold'>{props.title}</h2>
                <p>{props.text}</p>
                <div className='flex flex-row self-end gap-x-4'>
                    <button ref={btnOkay} className='bg-grey hover:bg-grey-lighter rounded px-3 py-2' onClick={close}>Okay</button>
                </div>
            </div>
        </section>
    );
}

export default InfoModal;

