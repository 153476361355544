import {React, useState, useEffect, useCallback, useRef} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {LazyLoadImage} from "react-lazy-load-image-component";

function ViewerModal(props) {
    const btnExit = useRef(null)

    useEffect(() => {
        setVisible(props.visible);
        if (props.visible) setTimeout(() => btnExit.current.focus(), 0)
    }, [props.visible])

    const [visible, setVisible] = useState(false);

    const cancel = (e) => {
        if (!e.defaultPrevented) props.res();
    }

    return (
        <section className={`fixed z-50 top-0 left-0 flex flex-col justify-center items-center bg-black h-screen w-screen ${!visible && 'hidden'}`}>
            {
                props.image !== undefined &&
                <LazyLoadImage key={props.image.uuid} alt=''
                               placeholderSrc={'/images/' + props.image.preview.mid}
                               src={'/images/' + props.image.original}
                               effect='none'
                               className='pointer-events-none'
                />
            }
            <div className='fixed bottom-0 left-0 hidden md:flex flex-row gap-x-8 justify-center py-8 w-full'>
                <button ref={btnExit} className='flex flex-row justify-center items-center bg-offwhite hover:bg-grey-lightest text-grey-dark text-4xl rounded-full h-20 w-20' onClick={cancel}><FontAwesomeIcon icon={faTimes} /></button>
            </div>
            <div className='fixed top-0 right-0 flex md:hidden flex-row gap-x-8 justify-end w-full'>
                <button ref={btnExit} className='bg-grey-dark/50 hover:bg-grey/75 text-offwhite text-2xl px-6 py-4' onClick={cancel}><FontAwesomeIcon icon={faTimes} /></button>
            </div>
        </section>
    );
}

export default ViewerModal;

