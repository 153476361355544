import {React} from 'react';
import {LazyLoadImage} from "react-lazy-load-image-component";
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAnglesLeft,
    faCircleCheck,
    faCircleXmark
} from "@fortawesome/free-solid-svg-icons";
import { Transition } from "@headlessui/react";

function Contact(props) {
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        await fetch('https://api.web3forms.com/submit', {method: 'POST', body: new FormData(e.target)})
            .then(response => response.json())
            .then(data => {
                if (data.success === true) navigate('./success')
                else navigate('./failure')
            });
    }

    return (
        <Transition
            as='section'
            show={true}
            appear={true}
            enter="transition-transform duration-300"
            enterFrom="transform translate-x-full"
            enterTo="transform translate-x-0"
            className='Contact fixed top-0 z-20 bg-grey-dark h-screen w-screen transform translate-x-full'>
            {
                (props.images.length && props.row !== undefined) ?
                <LazyLoadImage key='hero_img' alt=''
                               placeholderSrc={'/images/' + props.images.find(image => image.uuid === props.row.backdrops.subpages).preview.mid}
                               src={'/images/' + props.images.find(image => image.uuid === props.row.backdrops.subpages).preview.large}
                               effect='none' className='object-cover h-screen w-screen'
                               wrapperClassName='absolute top-0 bg-cover bg-center h-screen w-screen'
                /> : ''
            }
            <div className='relative flex flex-col justify-center items-center bg-gradient-to-b from-grey-dark/50 via-grey-dark/25 to-grey-dark/50 h-full overflow-auto w-full p-4 xl:px-44 2xl:px-64'>
                <div className='space-y-6 md:max-w-xl xl:max-w-2xl 2xl:max-w-3xl 3xl:max-w-4xl w-full'>
                    <div className='flex flex-col gap-y-2'>
                        <h1 className='text-3xl font-semibold uppercase w-full'>Contact</h1>
                        <Link to='/' className='flex flex-row items-center gap-x-3 hover:text-grey-lightest uppercase w-full'>
                            <FontAwesomeIcon icon={faAnglesLeft} />
                            Home
                        </Link>
                    </div>
                    <div className='flex flex-row bg-grey-darker/25 rounded-2xl backdrop-blur-xl w-full p-6 md:p-12'>
                        <Routes>
                            <Route exact path='/' element={
                                <form className='flex flex-col gap-y-4 w-full md:w-2/3' onSubmit={handleSubmit}>
                                    <input type='hidden' name='access_key' value='9453777f-c818-46d0-af5c-1d0343ed268f' />
                                    <input type="hidden" name="subject" value="Neue Anfrage!" />
                                    <input type="hidden" name="from_name" value="tbvisualarts.com/" />
                                    <input type="checkbox" name="botcheck" className="hidden" />
                                    <div className='flex flex-col gap-y-2'>
                                        <span>Vorname</span>
                                        <input type='text' name='name' required={true} className='text-lg text-black rounded px-2 py-1' />
                                    </div>
                                    <div className='flex flex-col gap-y-2'>
                                        <span>Name</span>
                                        <input type='text' name='lastname' required={true} className='text-lg text-black rounded px-2 py-1' />
                                    </div>
                                    <div className='flex flex-col gap-y-2'>
                                        <span>E-Mail</span>
                                        <input type='email' name='email' required={true} className='text-lg text-black rounded px-2 py-1' />
                                    </div>
                                    <div className='flex flex-col gap-y-2'>
                                        <span>Message</span>
                                        <textarea name='message' rows={4} required={true} className='text-lg text-black rounded px-2 py-1' />
                                    </div>
                                    <button type='submit' className='self-end bg-white hover:bg-grey-lightest text-grey-dark rounded mt-2 px-4 py-2'>Anfrage senden</button>
                                </form>
                            } />
                            <Route path='/success' element={
                                <div className='flex flex-row justify-center items-center gap-x-4 w-2/3'>
                                    <FontAwesomeIcon icon={faCircleCheck} className='text-4xl' />
                                    Erfolgreich gesendet!
                                </div>
                            } />
                            <Route path='/failure' element={
                                <div className='flex flex-row justify-center items-center gap-x-4 w-2/3'>
                                    <FontAwesomeIcon icon={faCircleXmark} className='text-4xl' />
                                    Es ist ein Fehler unterlaufen!
                                </div>
                            } />
                        </Routes>
                        <div className='hidden md:flex flex-row justify-center items-center w-1/3 md:pl-12'>
                            <img src='/logo.svg' alt='logo' className='h-24 2xl:h-32' />
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    );
}

export default Contact;

