import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMessage as farMessage} from "@fortawesome/free-regular-svg-icons";
import {
    faFacebookSquare,
    faInstagram,
    faLinkedin,
    faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons'
import {faArrowRight, faBars} from "@fortawesome/free-solid-svg-icons";
import {Link, Route, Routes} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import Marquee from "react-fast-marquee";
import ViewerModal from "./ViewerModal";
import Contact from "./Contact";
import Services from "./Services";
import Impressum from "./Impressum";
import Datenschutz from "./Datenschutz";
import { Transition } from "@headlessui/react";

function App() {
    const dev = false;
    const protocol = 'https'

    const [images, setImages] = useState([])
    const [portfolio, setPortfolio] = useState([])
    const [services, setServices] = useState([])
    const [partners, setPartners] = useState([])
    const [about, setAbout] = useState([])
    const [aboutImage, setAboutImage] = useState(null)

    const port = dev ?
        protocol === 'https' ? 443 : 8888
        : protocol === 'https' ? 443 : 80
    const subPath = dev ? '' : '/server';

    useEffect(() => { /* calls two times due to "wrongly use of useEffect. no worries, it is vanishes in production build" */
        fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/get_images.php')
            .then(response => response.json())
            .then(data => setImages(data));

        fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/get_portfolio.php')
            .then(response => response.json())
            .then(data => {
                const portfolioFiltered = data.filter(row => row.visible);
                const firstPinned = 'pinned' in portfolioFiltered[0] ? portfolioFiltered[0].pinned : false;
                let portfolioShuffled; // all rows pinned => no shuffle
                if (firstPinned) portfolioShuffled = getRowsPinned(portfolioFiltered) ? portfolioFiltered : [portfolioFiltered[0], ...shuffle(portfolioFiltered.slice(1))]
                else portfolioShuffled = getRowsPinned(portfolioFiltered) ? portfolioFiltered : shuffle(portfolioFiltered)
                setPortfolio(portfolioShuffled)
            });

        fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/get_services.php')
            .then(response => response.json())
            .then(data => setServices(data));

        fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/get_partners.php')
            .then(response => response.json())
            .then(data => setPartners(shuffle(data)));

        fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/get_about.php')
            .then(response => response.json())
            .then(data => setAbout(shuffle(data)));
    }, [])

    useEffect(() => {
        if (images.length && about.length) {
            const aboutImages = [];
            about.map(abou => aboutImages.push(images.find(image => image.uuid === abou.image)))
            const aboutImagesFiltered = aboutImages.filter(image => image.visible)
            setAboutImage(aboutImagesFiltered[Math.floor(Math.random()*aboutImagesFiltered.length)])
        }
    }, [images, about])


    const getRowsPinned = (a) => {
        let allPinned = true;
        a.forEach(row => allPinned = row.pinned ? allPinned : false);
        return allPinned;
    }

    const shuffle = (a) => {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    const getAge = (dob) => {
        let today = new Date();
        let birthDate = new Date(dob);  // create a date object directly from 'dob' argument
        let age_now = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age_now--;
        return age_now;
    }

    const anchorPortfolio = useRef(null);
    const anchorServices = useRef(null);
    const anchorPartners = useRef(null);
    const anchorAbout = useRef(null);

    const [mobileNavVis, setMobileNavVis] = useState(false)


    const openViewer = (uuid) => setViewer({'visible': true, 'image': images[images.map(i => i.uuid).indexOf(uuid)]});

    const [viewer, setViewer] = useState({'visible': false, 'image': undefined});
    const resetViewer = () => setViewer({'visible': false, 'image': undefined});

    return (
        <>
            <div className='App bg-grey-dark text-offwhite text-sm xl:text-base 2xl:text-xl font-montserrat min-h-screen'>
                <section className='relative Hero min-h-screen'>
                    {
                        (images.length && portfolio.length) ?
                            <LazyLoadImage key='hero_img' alt=''
                                           placeholderSrc={'/images/' + images.find(image => image.uuid === portfolio[0].backdrops.hero).preview.mid}
                                           src={'/images/' + images.find(image => image.uuid === portfolio[0].backdrops.hero).preview.large}
                                           effect='none' className='object-cover h-full w-full'
                                           wrapperClassName='absolute top-0 bg-cover bg-center h-full w-full'
                            /> : ''
                    }
                    <div className='relative flex flex-col justify-between items-center bg-gradient-to-b from-grey-dark/50 xl:from-grey-dark/75 via-grey-dark/25 xl:via-transparent to-grey-dark min-h-screen w-full px-4 md:px-12 xl:px-44 3xl:px-0'>
                        <header className='MobileHeader relative flex md:hidden justify-center text-center w-full pt-16'>
                            <img src='/logo.svg' alt='logo' className='h-28' />
                            <button className='absolute top-0 right-0 text-4xl mt-6 p-4' onClick={()=>setMobileNavVis(v => true)}><FontAwesomeIcon icon={faBars} /></button>
                            <Transition
                                as='section'
                                show={mobileNavVis}
                                enter='transition-transform duration-300'
                                enterFrom='transform -translate-x-full'
                                enterTo='transform translate-x-0'
                                leave='transition-transform duration-300'
                                leaveFrom='transform translate-x-0'
                                leaveTo='transform -translate-x-full'
                                className='fixed top-0 left-0 z-20 h-screen w-screen'>
                                {
                                    (images.length && portfolio.length) ?
                                        <LazyLoadImage key='hero_img' alt=''
                                                       placeholderSrc={'/images/' + images.find(image => image.uuid === portfolio[0].backdrops.hero).preview.mid}
                                                       src={'/images/' + images.find(image => image.uuid === portfolio[0].backdrops.hero).preview.large}
                                                       effect='none' className='object-cover h-screen w-screen'
                                                       wrapperClassName='absolute top-0 left-0 bg-cover bg-center h-screen w-screen'
                                        /> : ''
                                }
                                <div className='relative flex flex-col justify-between bg-grey-darker/25 backdrop-blur-lg text-xl h-screen w-full px-4'>
                                    <div className='flex flex-row justify-between items-center mt-6'>
                                        <span className='p-4'>DE</span>
                                        <button className='text-4xl p-4' onClick={()=>setMobileNavVis(v => false)}><FontAwesomeIcon icon={faArrowRight} /></button>
                                    </div>
                                    <nav className='flex flex-col justify-center items center space-y-8 uppercase' onClick={()=>setMobileNavVis(v => false)}>
                                        <b>Home</b>
                                        <a className='hover:text-grey-lightest' onClick={()=>anchorPortfolio.current.scrollIntoView({behavior: "smooth"})}>Portfolio</a>
                                        <a className='hover:text-grey-lightest' onClick={()=>anchorServices.current.scrollIntoView({behavior: "smooth"})}>Services</a>
                                        <a className='hover:text-grey-lightest' onClick={()=>anchorPartners.current.scrollIntoView({behavior: "smooth"})}>Partners</a>
                                        <a className='hover:text-grey-lightest' onClick={()=>anchorAbout.current.scrollIntoView({behavior: "smooth"})}>About</a>
                                        <Link to='contact' className='hover:text-grey-lightest'>Contact</Link>
                                    </nav>
                                    <div className='flex flex-col justify-center items-center space-y-8 py-10'>
                                        <img src='/logo.svg' alt='logo' className='h-20' />
                                        <div className='flex flex-col space-y-4 font-semibold'>
                                            <h1>#tbvisualarts</h1>
                                            <div className='flex flex-row justify-center space-x-8 text-3xl'>
                                                <a target='_blank' href='https://de.linkedin.com/in/tobias-br%C3%B6ning-549b89227' className='flex hover:text-grey-light'><FontAwesomeIcon icon={faLinkedin} /></a>
                                                <a target='_blank' href='https://de-de.facebook.com/pg/tb.visualarts' className='flex hover:text-grey-light'><FontAwesomeIcon icon={faFacebookSquare} /></a>
                                                <a target='_blank' href='https://www.instagram.com/tb.visualarts/' className='flex hover:text-grey-light'><FontAwesomeIcon icon={faInstagram} /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Transition>
                        </header>
                        <header className='DesktopHeader hidden md:flex flex-row justify-between items-center 2xl:text-lg border-b-2 border-offwhite/25 w-full 3xl:max-w-screen-2xl py-4 md:pt-8'>
                            <img src='/logo.svg' alt='logo' className='h-20 xl:h-24 2xl:h-32' />
                            <nav className='flex flex-row justify-center space-x-8 uppercase children:cursor-pointer'>
                                <b>Home</b>
                                <a className='hover:text-grey-lightest' onClick={()=>anchorPortfolio.current.scrollIntoView({behavior: "smooth"})}>Portfolio</a>
                                <a className='hover:text-grey-lightest' onClick={()=>anchorServices.current.scrollIntoView({behavior: "smooth"})}>Services</a>
                                <a className='hover:text-grey-lightest' onClick={()=>anchorPartners.current.scrollIntoView({behavior: "smooth"})}>Partners</a>
                                <a className='hover:text-grey-lightest' onClick={()=>anchorAbout.current.scrollIntoView({behavior: "smooth"})}>About</a>
                                <Link to='contact' className='hover:text-grey-lightest'>Contact</Link>
                            </nav>
                            <span>DE</span>
                        </header>
                        <div className='w-full 3xl:max-w-screen-2xl pb-24 xl:pb-0 my-16'>
                            <h1 className='text-4xl md:text-6xl 2xl:text-75xl font-black leading-tight 2xl:leading-snug uppercase'>More than<br/>a picture</h1>
                            <p className='xl:text-sm 2xl:text-lg py-4 md:w-3/5 xl:w-2/5'>Your professional contact in terms of quality.</p>
                            <hr className='2xl:border-t-2 md:w-3/5 xl:w-2/5' />
                            <Link to='contact' className='inline-block bg-offwhite hover:bg-grey-lightest text-grey-dark text-sm 2xl:text-lg font-semibold uppercase rounded-lg mt-8 px-4 py-2'>
                                Kontakt
                                <FontAwesomeIcon icon={farMessage} className='ml-4' />
                            </Link>
                        </div>
                        <div className='spacer h-24'></div>
                    </div>
                </section>
                <section className='relative Portfolio w-full 3xl:max-w-screen-2xl px-4 md:px-12 xl:px-44 3xl:px-0 -mt-40 xl:-mt-24 2xl:-mt-36 3xl:mx-auto'>
                    <span ref={anchorPortfolio} className='absolute -top-24'></span>
                    <h2 className='text-xl 2xl:text-4xl font-semibold uppercase mb-8'>Portfolio</h2>
                    <div className='flex flex-col space-y-2 2xl:space-y-4'>
                        {
                            portfolio.map(row => {
                                const index = portfolio.map(i => i.uuid).indexOf(row.uuid);
                                return row.visible ? <Marquee key={row.uuid} gradient={false} speed={/*50*/ 10 + Math.random()*50}
                                                 direction={index % 2 > 0 ? 'left' : 'right'} className='rounded-xl'>
                                    {
                                        row.images.map(imageUuid => {
                                            const image = images.find(i => i.uuid === imageUuid);
                                            if (image !== undefined && image.visible)
                                                return <LazyLoadImage key={image.uuid} alt=''
                                                                      placeholderSrc={'/images/' + image.preview.tiny}
                                                                      src={'/images/' + image.preview.mid} effect='none'
                                                                      className='object-cover rounded-xl'
                                                                      wrapperClassName='bg-cover bg-center rounded-xl aspect-[3/2] w-44 xl:w-56 2xl:w-72 mx-1 2xl:mx-2'
                                                                      onClick={()=>openViewer(image.uuid)}
                                                />
                                        })
                                    }
                                </Marquee> : ''
                            })
                        }
                    </div>
                </section>
                <section ref={anchorServices} className='Services w-full 3xl:max-w-screen-2xl 3xl:mx-auto px-4 md:px-12 xl:px-44 3xl:px-0 py-24 xl:py-36'>
                    <h2 className='text-xl 2xl:text-4xl font-semibold uppercase mb-8'>Services</h2>
                    <div className='flex flex-col md:flex-row justify-between md:space-x-8 xl:space-x-24 2xl:space-x-32 space-y-6 md:space-y-0'>
                        {
                            (images.length && services.length) ? services.map(service =>
                                <div  key={'services_'+service.uuid} className='relative bg-grey w-full xl:w-1/3 rounded-2xl'>
                                    {
                                        (images.length && services.length && images.find(image => image.uuid === service.backdrop) !== undefined) ?
                                            <LazyLoadImage alt=''
                                                           placeholderSrc={'/images/' + images.find(image => image.uuid === service.backdrop).preview.mid}
                                                           src={'/images/' + images.find(image => image.uuid === service.backdrop).preview.large}
                                                           effect='none' className='object-cover rounded-2xl h-full w-full'
                                                           wrapperClassName='absolute top-0 bg-cover bg-center rounded-2xl h-full w-full'
                                            /> : ''
                                    }
                                    <div className='relative flex flex-col justify-between space-y-8 bg-grey-dark/70 2xl:text-lg rounded-2xl p-8 3xl:p-14 h-full'>
                                        <h3 className='text-center text-xl 2xl:text-2xl font-semibold uppercase'>{service.title}</h3>
                                        {
                                            service.preview.type === "list" ?
                                            <ul className='list-disc pl-4'>
                                                {
                                                    service.preview.content.map((li, i) => <li key={'services_'+service.uuid+'-prev_'+i}>{li}</li>)
                                                }
                                            </ul> :
                                            <p className='line-clamp-10'>{service.preview.content}</p>
                                        }
                                        <Link to={'service/'+service.uuid} className='hover:bg-offwhite border-offwhite border-2 hover:text-grey-dark text-center font-semibold rounded-xl py-2 duration-150'>mehr erfahren</Link>
                                    </div>
                                </div>
                            ) : ''
                        }
                    </div>
                </section>
                <section ref={anchorPartners} className='relative Partners'>
                    {
                        (images.length && portfolio.length) ?
                            <LazyLoadImage alt=''
                                           placeholderSrc={'/images/' + images.find(image => image.uuid === portfolio[0].backdrops.partners).preview.mid}
                                           src={'/images/' + images.find(image => image.uuid === portfolio[0].backdrops.partners).preview.large}
                                           effect='none' className='object-cover h-full w-full'
                                           wrapperClassName='absolute top-0 left-0 bg-cover bg-center h-full w-full'
                            /> : ''
                    }
                    <div className='relative flex flex-col items-center bg-gradient-to-b from-transparent to-grey-dark px-4 md:px-12 xl:px-44 3xl:px-0 py-24 xl:py-36'>
                        <h2 className='text-xl 2xl:text-4xl font-semibold uppercase w-full 3xl:max-w-screen-2xl mb-6'>Partners</h2>
                        <Marquee gradient={false} speed={10} className='rounded-xl w-full 3xl:max-w-screen-2xl'>
                            {
                                partners.map(partner => {
                                    const image = images.find(i => i.uuid === partner.logo);
                                    if (image !== undefined && image.visible)
                                        return <a target='_blank' href={partner.url} key={'partner_'+partner.uuid}>
                                            <LazyLoadImage alt=''
                                                placeholderSrc={'/images/' + image.preview.tiny}
                                                src={'/images/' + image.preview.mid} effect='none'
                                                className='object-contain h-full w-full aspect-[3/2]'
                                                wrapperClassName='flex flex-col justify-center bg-grey-light/25 bg-contain bg-center rounded-xl backdrop-blur aspect-[3/2] w-56 2xl:w-72 mx-1 2xl:mx-2 p-4'
                                            />
                                        </a>
                                })
                            }
                        </Marquee>
                        <Link to='contact' className='self-center bg-offwhite hover:bg-grey-lightest text-grey-dark text-sm 2xl:text-xl font-semibold uppercase rounded-lg mt-16 px-4 py-2'>Partner werden</Link>
                    </div>
                </section>
                <section ref={anchorAbout} className='About w-full 3xl:max-w-screen-2xl 3xl:mx-auto px-4 md:px-12 xl:px-44 3xl:px-0 py-24 xl:py-36'>
                    <h2 className='text-xl 2xl:text-4xl font-semibold uppercase mb-8'>About</h2>
                    <div className='flex flex-col md:flex-row items-center md:space-x-12 xl:space-x-24 2xl:space-x-32 space-y-8 xl:space-y-0'>
                        <div className="flex flex-col space-y-4 text-justify md:w-2/3">
                            <p>
                                Servus, ich bin Tobi, { getAge('1996-05-27') } Jahre alt und bin seit 2021 selbstständig mit meinem Gewerbe „TB Visual Arts“. Ich bin ein professioneller und leidenschaftlicher Fotograf im Bereich „Automotive“. Des Weiteren bin ich Cloud Ops Engineer bei einem renommierten Gabelstapler und Flurförderfahrzeughersteller. Durch meine IT- Ausbildung bei BMW, wurde meine Leidenschaft für Automobile entfacht. Nur wie kam ich nun zur Fotografie? Wie es der Zufall manchmal so möchte, kaufte ich mir von meinem ersten Gehalt eine DSLM, erstmal nur aus Spaß. Aus Spaß wurde sehr schnell eine wahre Leidenschaft und nach unzähligen Fotos von Bergen, Städten, Hunden und Menschen bin ich selbst in der Nachtfotografie immer wieder bei einem Thema stehen geblieben: Autos.
                            </p>
                            <p>
                                Gegen Ende 2020 spezialisierte ich mich auf Autos und suchte mir erste Kunden via TFP-Shootings. Die Fotos fanden im Freundeskreis, aber auch bei den Kunden großen Anklang. Nach sorgfältigen Überlegungen gründete ich 2021 mein Gewerbe TB Visual Arts. Seither haben sowohl Privatpersonen wie auch Unternehmen meine Dienstleistungen in Anspruch genommen. Ich würde mich selbst als perfektionistisch und experimentierfreudig bezeichnen und dies übertrage ich auch auf meine Tätigkeit als Fotograf. Ich bin für jeden Spaß zu haben und wer mich kennt weiß, dass Humor zu meinen Shootings immer mit dazu gehört.
                            </p>
                        </div>
                        <div className="w-full md:w-1/3">
                            {
                                (aboutImage) ?
                                <LazyLoadImage alt=''
                                               placeholderSrc={'/images/' + aboutImage.preview.small}
                                               src={'/images/' + aboutImage.preview.large} effect='none'
                                               className='object-contain rounded-2xl aspect-[3/4] w-full'
                                               wrapperClassName='bg-cover bg-center rounded-2xl aspect-[3/4] w-full'
                                /> : ''
                            }
                        </div>
                    </div>
                </section>
                <footer className='relative'>
                    {
                        (images.length && portfolio.length) ?
                        <LazyLoadImage alt=''
                                       placeholderSrc={'/images/' + images.find(image => image.uuid === portfolio[0].backdrops.footer).preview.mid}
                                       src={'/images/' + images.find(image => image.uuid === portfolio[0].backdrops.footer).preview.large}
                                       effect='none' className='object-cover h-full w-full'
                                       wrapperClassName='absolute top-0 left-0 bg-cover bg-center h-full w-full'
                        /> : ''
                    }
                    <div className='relative bg-grey-dark/75 flex flex-col items-center px-4 md:px-12 xl:px-44 3xl:px-0'>
                        <div className='flex flex-row justify-between border-b-2 border-offwhite w-full 3xl:max-w-screen-2xl py-4'>
                            <div className='flex flex-col space-y-4'>
                                <span className='text-xl font-bold'>tbvisualarts</span>
                                <div className='flex flex-row space-x-4'>
                                    <a target='_blank' href='https://de.linkedin.com/in/tobias-br%C3%B6ning-549b89227'><FontAwesomeIcon icon={faLinkedin} className='hover:text-grey-lightest text-2xl' /></a>
                                    <a target='_blank' href='https://de-de.facebook.com/pg/tb.visualarts'><FontAwesomeIcon icon={faFacebookSquare} className='hover:text-grey-lightest text-2xl' /></a>
                                    <a target='_blank' href='https://www.instagram.com/tb.visualarts/'><FontAwesomeIcon icon={faInstagram} className='hover:text-grey-lightest text-2xl' /></a>
                                </div>
                            </div>
                            <img src='/logo.svg' alt='logo' className='h-20 2xl:h-24' />
                            <ul className='flex flex-col justify-between text-sm 2xl:text-base text-right uppercase'>
                                <li className='hover:underline'><Link to='impressum'>Impressum</Link></li>
                                <li className='hover:underline'><Link to='datenschutz'>Datenschutz</Link></li>
                                <li className='hover:underline'><a target='_blank' href='/AGB_TB_Visual_Arts.pdf'>AGB</a></li>
                            </ul>
                        </div>
                        <div className='flex flex-col xl:flex-row justify-between text-sm 2xl:text-base w-full 3xl:max-w-screen-2xl py-4'>
                            <span className='uppercase'>Copyright 2022 tbvisualarts</span>
                            <a target='_blank' href='http://double-n-studio.com' className='hover:text-grey-lightest underline'>double-n-studio.com</a>
                        </div>
                    </div>
                </footer>
                {
                    /*
                    <FontAwesomeIcon className='text-green-700 text-2xl m-8' icon={faCoffee} />
                    <Link to='/dashboard'>Dashboard</Link>
                    */
                }
                <aside className='fixed top-1/2 right-0 z-30 -translate-y-1/2 hidden md:flex flex-col justify-center space-y-3 bg-offwhite text-grey-dark text-2xl rounded-l-xl hover:pr-8 px-2 py-3 duration-300'>
                    <a target='_blank' href='https://www.instagram.com/tb.visualarts' className='flex hover:text-grey-light'><FontAwesomeIcon icon={faInstagram} /></a>
                    <a target='_blank' href='https://de-de.facebook.com/pg/tb.visualarts' className='flex hover:text-grey-light'><FontAwesomeIcon icon={faFacebookSquare} /></a>
                    <a target='_blank' href='https://de.linkedin.com/in/tobias-br%C3%B6ning-549b89227' className='flex hover:text-grey-light'><FontAwesomeIcon icon={faLinkedinIn} /></a>
                    <Link to='contact'><FontAwesomeIcon icon={farMessage} className='text-xl hover:text-grey-light' /></Link>
                </aside>
                <Routes>
                    <Route path='impressum/' element={<Impressum images={images} row={portfolio[0]} />} />
                    <Route path='datenschutz/' element={<Datenschutz images={images} row={portfolio[0]} />} />
                    <Route path='contact/*' element={<Contact images={images} row={portfolio[0]} />} />
                    <Route path='service/:imageUuid' element={<Services openViewer={openViewer} images={images} services={services} />} />
                    <Route path='service/' element={<Services/>} />
                </Routes>
                <ViewerModal image={viewer.image} visible={viewer.visible} res={resetViewer} />
            </div>
        </>
    );
}

export default App;
