import {React} from 'react';
import {LazyLoadImage} from "react-lazy-load-image-component";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { Transition } from "@headlessui/react";

function Impressum(props) {
    return (
        <Transition
            as='section'
            show={true}
            appear={true}
            enter="transition-top duration-300"
            enterFrom="top-full"
            enterTo="top-0"
            className='Impressum fixed top-full z-20 bg-grey-dark h-screen w-screen'>
            {
                (props.images.length && props.row !== undefined) ?
                    <LazyLoadImage key='hero_img' alt=''
                                   placeholderSrc={'/images/' + props.images.find(image => image.uuid === props.row.backdrops.subpages).preview.mid}
                                   src={'/images/' + props.images.find(image => image.uuid === props.row.backdrops.subpages).preview.large}
                                   effect='none' className='object-cover h-screen w-screen'
                                   wrapperClassName='absolute top-0 bg-cover bg-center h-screen w-screen'
                    /> : ''
            }
            <div className='relative flex flex-col items-center bg-gradient-to-b from-grey-dark/50 via-grey-dark/25 to-grey-dark/50 h-full overflow-auto w-full p-4 xl:px-44 2xl:px-64 py-24 2xl:px-44'>
                <div className='space-y-6 md:max-w-xl xl:max-w-2xl 2xl:max-w-3xl 3xl:max-w-4xl w-full'>
                    <div className='flex flex-col gap-y-2'>
                        <h1 className='text-3xl font-semibold uppercase w-full'>Impressum</h1>
                        <Link to='/' className='flex flex-row items-center gap-x-3 hover:text-grey-lightest uppercase w-full'>
                            <FontAwesomeIcon icon={faAnglesLeft} />
                            Home
                        </Link>
                    </div>
                    <div className='bg-grey-darker/25 rounded-2xl backdrop-blur-xl w-full p-6 md:p-12'>
                        <h2 className='text-xl font-semibold mb-2'>Angaben gemäß § 5 TMG</h2>
                        <p>Tobias Bröning<br />TB Visual Arts<br />Bahnhofstraße 56<br />63538 Großkrotzenburg</p>
                        <h2 className='text-xl font-semibold mb-2 mt-12'>Kontakt</h2>
                        <p>Telefon: 01602348816<br />E-Mail: info@tbvisualarts.com</p>
                        <h2 className='text-xl font-semibold mb-2 mt-12'>Gewerbeanmeldung</h2>
                        <p>Die Gewerbeanmeldung nach §15 Abs. 1 GewO wurde am 15.08.2022 durch die Gemeinde Großkrotzenburg erteilt.</p>
                        <h2 className='text-xl font-semibold mb-2 mt-12'>Verbraucherstreitbeilegung/ Universalschlichtungsstelle</h2>
                        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
                        <p className='pt-12'>Quelle: <a href='https://www.e-recht24.de' className='underline'>eRecht24</a></p>
                    </div>
                </div>
            </div>
        </Transition>
    );
}

export default Impressum;

