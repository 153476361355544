import {React, useState, useEffect, useCallback, useRef} from 'react';
import Cropper from "react-easy-crop";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudArrowUp, faTimes} from "@fortawesome/free-solid-svg-icons";

function CropperModal(props) {
    const btnUpload = useRef(null)

    useEffect(() => {
        setVisible(props.visible);
        if (props.visible) setTimeout(() => btnUpload.current.focus(), 0)
    }, [props.visible])

    const [visible, setVisible] = useState(false);

    const cancel = (e) => {
        if (!e.defaultPrevented) props.res();
    }
    const upload = () => {
        props.param.append('specs', JSON.stringify(specs));
        props.callback(props.param, props.src);
        props.res();
    }

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        //console.log(croppedArea, croppedAreaPixels)
        setSpecs(croppedAreaPixels)
    }, [])
    const [specs, setSpecs] = useState({width: 0, height: 0, x: 0, y: 0})

    return (
        <section className={`fixed z-50 top-0 left-0 flex flex-col justify-center items-center bg-black/75 h-screen w-screen ${!visible && 'hidden'}`}>
            { props.aspect === 0 ?
                <Cropper
                    image={props.src}
                    crop={crop}
                    zoom={zoom}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                /> :
                <Cropper
                    image={props.src}
                    crop={crop}
                    zoom={zoom}
                    aspect={props.aspect}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            }
            <div className='fixed bottom-0 left-0 flex flex-row gap-x-8 justify-center py-8 w-full'>
                <button ref={btnUpload} className='flex flex-row justify-center items-center bg-offwhite hover:bg-grey-lightest text-grey-dark text-3xl rounded-full h-20 w-20' onClick={upload}><FontAwesomeIcon icon={faCloudArrowUp} /></button>
                <button className='flex flex-row justify-center items-center bg-primary hover:bg-grey-lighter text-4xl rounded-full h-20 w-20' onClick={cancel}><FontAwesomeIcon icon={faTimes} /></button>
            </div>
        </section>
    );
}

export default CropperModal;

