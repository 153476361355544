import React, {useEffect, useState} from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "./lazy-load.css";
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {Menu} from '@headlessui/react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEllipsisVertical,
    faEye,
    faEyeSlash, faFolderTree,
    faHandshake,
    faHouse, faPanorama, faShoePrints,
    faTrashAlt
} from "@fortawesome/free-solid-svg-icons";

function DashboardSortableImage(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: props.id});

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    };

    const [isBackdrop, setIsBackdrop] = useState(false)
    useEffect(() => {
        if (props.row !== undefined) {
            if (props.row.backdrops !== undefined)
                if (props.image.uuid === props.row.backdrops.hero ||
                    props.image.uuid === props.row.backdrops.partners ||
                    props.image.uuid === props.row.backdrops.subpages ||
                    props.image.uuid === props.row.backdrops.footer) setIsBackdrop(true)
            else if (props.row.backdrop !== undefined) {
                if (props.image.uuid === props.row.backdrop) setIsBackdrop(true)
            } else setIsBackdrop(false)
        }
    }, [props])

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners} className={`relative flex shrink-0 w-52 overflow-visible overflow-x-auto`}>
            { props.row ?
                    <>
                        <LazyLoadImage key={props.id} alt='' placeholderSrc={'/images/' + props.image.preview.tiny} src={'/images/' + props.image.preview.mid} effect='none' className={`bg-gray-200 h-full`} wrapperClassName={`${!props.row.visible || !props.image.visible ? 'opacity-20' : ''}`} />
                        { props.row.backdrops !== undefined ?
                            <div className='absolute bottom-0 left-0 flex flex-row items-center bg-grey-dark/75 rounded-tr-xl'>
                                { (props.row.backdrops.hero === undefined || props.image.uuid !== props.row.backdrops.hero) ? '' :
                                    <FontAwesomeIcon icon={faHouse} className='px-3 py-2' />
                                }
                                { (props.row.backdrops.partners === undefined || props.image.uuid !== props.row.backdrops.partners) ? '' :
                                    <FontAwesomeIcon icon={faHandshake} className='px-3 py-2'/>
                                }
                                { (props.row.backdrops.subpages === undefined || props.image.uuid !== props.row.backdrops.subpages) ? '' :
                                    <FontAwesomeIcon icon={faFolderTree} className='px-3 py-2'/>
                                }
                                { (props.row.backdrops.footer === undefined || props.image.uuid !== props.row.backdrops.footer) ? '' :
                                    <FontAwesomeIcon icon={faShoePrints} className='px-3 py-2'/>
                                }
                            </div> : ''
                        }
                        { props.row.backdrop !== undefined ?
                            <div className='absolute bottom-0 left-0 flex flex-row items-center bg-grey-dark/75 rounded-tr-xl'>
                                { (props.image.uuid !== props.row.backdrop) ? '' :
                                    <FontAwesomeIcon icon={faPanorama} className='px-3 py-2' />
                                }
                            </div> : ''
                        }
                    </>
                : <LazyLoadImage key={props.id} alt='' placeholderSrc={'/images/' + props.image.preview.tiny} src={'/images/' + props.image.preview.mid} effect='none' className={`bg-gray-200 h-full`} wrapperClassName={`${!props.image.visible ? 'opacity-20' : ''}`} />
            }
            <Menu as='div' className='absolute z-30 flex flex-col w-full overflow-visible'>
                <Menu.Button className='flex flex-row justify-center items-center self-end bg-grey-dark/75 rounded-bl-xl px-4 py-2'>
                    <FontAwesomeIcon icon={faEllipsisVertical} className='text-xl' />
                </Menu.Button>
                <Menu.Items className='flex flex-col divide-y divide-grey bg-grey-dark w-full mt-4'>
                    <Menu.Item as='div' className='flex flex-row items-center gap-x-4 hover:bg-grey p-4' onClick={()=>props.toggleVis(props.image.uuid)}>
                        <FontAwesomeIcon icon={props.image.visible ? faEyeSlash : faEye} />
                        <span>Sichtbarkeit</span>
                    </Menu.Item>
                    { props.row === undefined ? '' : <>
                        { props.row.backdrops === undefined ? '' : <>
                            <Menu.Item as='div'
                                       className={`flex flex-row items-center gap-x-4 hover:bg-grey p-4  ${props.image.uuid === props.row.backdrops.hero ? 'hidden' : ''}`}
                                       onClick={() => props.setBackdrop('hero', props.row.uuid, props.image.uuid)}
                            >
                                <FontAwesomeIcon icon={faHouse}/>
                                <span>Hero setzen</span>
                            </Menu.Item>
                            <Menu.Item as='div'
                                       className={`flex flex-row items-center gap-x-4 hover:bg-grey p-4  ${props.image.uuid === props.row.backdrops.partners ? 'hidden' : ''}`}
                                       onClick={() => props.setBackdrop('partners', props.row.uuid, props.image.uuid)}
                            >
                                <FontAwesomeIcon icon={faHandshake}/>
                                <span>Partners setzten</span>
                            </Menu.Item>
                            <Menu.Item as='div'
                                       className={`flex flex-row items-center gap-x-4 hover:bg-grey p-4  ${props.image.uuid === props.row.backdrops.subpages ? 'hidden' : ''}`}
                                       onClick={() => props.setBackdrop('subpages', props.row.uuid, props.image.uuid)}
                            >
                                <FontAwesomeIcon icon={faFolderTree}/>
                                <span>Subpages setzen</span>
                            </Menu.Item>
                            <Menu.Item as='div'
                                       className={`flex flex-row items-center gap-x-4 hover:bg-grey p-4  ${props.image.uuid === props.row.backdrops.footer ? 'hidden' : ''}`}
                                       onClick={() => props.setBackdrop('footer', props.row.uuid, props.image.uuid)}
                            >
                                <FontAwesomeIcon icon={faShoePrints}/>
                                <span>Footer setzen</span>
                            </Menu.Item>
                        </> }
                        { props.row.backdrop === undefined ? '' :
                            <Menu.Item as='div'
                                       className={`flex flex-row items-center gap-x-4 hover:bg-grey p-4  ${props.image.uuid === props.row.backdrop ? 'hidden' : ''}`}
                                       onClick={() => props.setBackdrop(props.row.uuid, props.image.uuid)}
                            >
                                <FontAwesomeIcon icon={faPanorama}/>
                                <span>Backdrop setzen</span>
                            </Menu.Item>
                        }
                        </>
                    }
                    { isBackdrop ?
                        <Menu.Item as='div'
                                   className='flex flex-row items-center gap-x-4 text-grey-light p-4'
                        >
                            <FontAwesomeIcon icon={faTrashAlt} className='' />
                            <span>Löschen</span>
                        </Menu.Item> :
                        <Menu.Item as='div'
                                   className='flex flex-row items-center gap-x-4 hover:bg-grey p-4'
                                   onClick={()=> props.del()}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} className='text-red-500' />
                            <span>Löschen</span>
                        </Menu.Item>
                    }
                </Menu.Items>
            </Menu>
        </div>
    );
}

export default DashboardSortableImage;